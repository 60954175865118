.__json-pretty__ {
    background: #fff !important;
    color: #404040 !important;
}

.__json-key__ {
    color: #238cff !important;
}

.__json-string__ {
    color: #ff8c23 !important;
}

.__json-boolean__ {
    color: #8c23ff !important;
}

.__json-value__ {
    color: #8cc63f !important;
}